import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  StyledAlphabetsContainer,
  StyledContainer,
  StyledDirectoryContent,
  StyledHeadline,
  StyledSubheader,
} from './alphabetized-directory.styles';

// Letters are broken into 2 separate lists, split after P to match the designs.
const LETTERS_FROM_A_TO_G = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
const LETTERS_FROM_H_TO_P = ['H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
const LETTERS_FROM_A_TO_P = [...LETTERS_FROM_A_TO_G, ...LETTERS_FROM_H_TO_P];
const LETTERS_FROM_Q_TO_Z = ['Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const AlphabetizedDirectory: FC = () => {
  return (
    <StyledContainer data-testid="alphabetized-directory-section">
      <StyledHeadline size="xxlarge" sizeWide="xxxlarge" noMargin>
        <FormattedMessage id="PROFILE_LO_FE_ALPHABETIZED_DIRECTORY_HEADING" />
      </StyledHeadline>
      <StyledDirectoryContent>
        <StyledSubheader noMargin>
          <FormattedMessage id="PROFILE_LO_FE_ALPHABETIZED_DIRECTORY_SUB_HEADING" />
        </StyledSubheader>
        {[LETTERS_FROM_A_TO_P, LETTERS_FROM_Q_TO_Z].map((charsArray, i) => (
          <StyledAlphabetsContainer key={i}>
            {charsArray.map((letter) => (
              <a
                href={`/people/${letter.toLowerCase()}/`}
                key={letter}
                title={letter}
                rel="index,follow"
                data-testid="alphabetized-directory-link"
              >
                {letter}
              </a>
            ))}
          </StyledAlphabetsContainer>
        ))}
      </StyledDirectoryContent>
    </StyledContainer>
  );
};
