import type { FC, ReactNode } from 'react';

import { Row, Column } from '@xing-com/xing-grid';

import { StyledGrid, StyledRow } from './entry-grid.styles';

interface IProps {
  aside?: ReactNode;
  bottom?: ReactNode;
  children: ReactNode;
}

export const EntryGrid: FC<IProps> = ({ children, aside, bottom }) =>
  children ? (
    <StyledGrid>
      <Row>
        <Column size={12} sizeTablet={9}>
          {children}
        </Column>
        {aside && (
          <Column size={0} sizeTablet={3}>
            {aside}
          </Column>
        )}
      </Row>
      {bottom && (
        <StyledRow>
          <Column size={12} sizeTablet={9}>
            {bottom}
          </Column>
        </StyledRow>
      )}
    </StyledGrid>
  ) : null;
