import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line node/no-unpublished-import
import type { ProfileLoProfileQuery } from '@xing-com/crate-profile-main-loader-logged-out';
import { Container } from '@xing-com/layout-primitives';

import { PageSection } from '../components/page-section';
import { RelatedProfileCard } from '../components/related-profile-card';
import { RelatedProfileCarousel } from '../components/related-profile-carousel';
import { ShowMore } from '../components/show-more';
import { TrackingLink } from '../components/tracking-link';
import type { XingIdNonError } from '../xing-id';

const genderHeadlineMapping = {
  MALE: 'PROFILE_LO_FE_USER_BAG_HEADLINE_MALE',
  FEMALE: 'PROFILE_LO_FE_USER_BAG_HEADLINE_FEMALE',
  NONE: 'PROFILE_LO_FE_USER_BAG_HEADLINE_NONE',
  OTHER: 'PROFILE_LO_FE_USER_BAG_HEADLINE_NONE',
};

export const UserBag: FC<{
  data: ProfileLoProfileQuery['peopleUserBag'];
  xingId: XingIdNonError;
}> = ({ data, xingId }) => {
  const { firstName, lastName, gender, pageName } = xingId;

  const userBagUrl = data?.userBagUrl ?? null;

  const userBagData = (data?.edges?.map((edge) => edge?.node) ?? [])
    .filter((user) => user !== null && user !== undefined)
    .filter(
      (user) =>
        user.pageName?.toLowerCase() !== pageName.toLowerCase() && user.hasPhoto
    );

  if (userBagData.length < 2) return null;

  const sectionHeadlineTranslationKey = genderHeadlineMapping[gender || 'NONE'];

  const sectionHeadline = (
    <FormattedMessage
      id={sectionHeadlineTranslationKey}
      values={{ firstName, lastName }}
    />
  );

  return (
    <PageSection headline={sectionHeadline} dataQa="user-bag-section">
      <Container>
        <RelatedProfileCarousel>
          {userBagData.map((user, index) =>
            user ? (
              <RelatedProfileCard
                key={index}
                otherProfile={user}
                trackingParamWithoutPrefix="upsell_user_bags_profiles"
              />
            ) : null
          )}
        </RelatedProfileCarousel>
        {userBagUrl && (
          <TrackingLink
            href={userBagUrl}
            trackingParamWithoutPrefix="upsell_user_bags_profiles"
          >
            <ShowMore
              size="large"
              translationKey="PROFILE_LO_FE_USER_BAG_SHOW_MORE_CTA"
              bold
            />
          </TrackingLink>
        )}
      </Container>
    </PageSection>
  );
};
