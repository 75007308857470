export const setCanonical = (canonical: string, pageName: string): string => {
  const canonicalUrl = new URL(canonical);
  const pageNameFromUrl = canonicalUrl.pathname.split('/')[2];
  if (pageNameFromUrl !== 'null') {
    return canonical;
  } else {
    const base = canonicalUrl.origin;
    const canonicalWithPageName = new URL(`/profile/${pageName}`, base);
    return canonicalWithPageName.href;
  }
};
