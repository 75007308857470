import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTracking } from '@xing-com/crate-communication-tracking';
import { RegistrationForm } from '@xing-com/crate-login-account-packages';
import { useLoginAppUrl } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';

import {
  StyledFormContainer,
  StyledHero,
  StyledLoginButton,
  StyledPopUpWindow,
  StyledSubHeadline,
} from './signup-layer.styles';

const neededFields: React.ComponentProps<
  typeof RegistrationForm
>['neededFields'] = ['email', 'password', 'tandcCheck'];

export const SignupLayer: FC<{ firstName: string }> = ({ firstName }) => {
  const [isShown, setIsShown] = useState(false);
  const { isRecurringUser } = useRuntimeConfig();
  const { pathname, search } = useLocation();

  const { track } = useTracking<{
    PropTrackAction: string;
    PropPostponeAction: number;
  }>();

  useEffect(() => {
    const { matches } = window.matchMedia('(min-width: 883px)');
    const override = new URLSearchParams(search).get('features_overrides');

    if (!matches) return;
    if (isRecurringUser) return;
    if (override === 'no_sign_up_layer') return;

    const waitTime = override === 'sign_up_layer' ? 0 : 5000;

    const timeout = window.setTimeout(() => {
      track({
        PropTrackAction: 'profile_lo_signup-layer',
        PropPostponeAction: 1,
      });
      setIsShown(true);
    }, waitTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [isRecurringUser, search, track]);

  const loginAppUrl = useLoginAppUrl();
  const loginLink = `${loginAppUrl}?dest_url=${encodeURIComponent(pathname)}&signup_channel=profileapp`;

  if (!isShown) return null;

  return (
    <StyledPopUpWindow isShown={isShown}>
      <StyledHero>
        <FormattedMessage
          id="PROFILE_LO_FE_SIGNUP_LAYER_HEADLINE"
          values={{ firstName }}
        />
      </StyledHero>
      <StyledSubHeadline size="large">
        <FormattedMessage id="PROFILE_LO_FE_SIGNUP_LAYER_SUB_HEADLINE" />
      </StyledSubHeadline>

      <StyledFormContainer>
        <RegistrationForm
          signupChannel="profileapp_signup_layer"
          hideHeadline
          emailOnly
          neededFields={neededFields}
        />
        <StyledLoginButton to={loginLink}>
          <FormattedMessage id="PROFILE_LO_FE_SIGNUP_LAYER_LOGIN_CTA" />
        </StyledLoginButton>
      </StyledFormContainer>
    </StyledPopUpWindow>
  );
};
