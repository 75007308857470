import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

// eslint-disable-next-line node/no-unpublished-import
import type { ProfileLoProfileQuery } from '@xing-com/crate-profile-main-loader-logged-out';

import { setCanonical } from './set-canonical';

type HeadSectionTagsProps = {
  pageName: string;
  data: ProfileLoProfileQuery['profilePageMeta'];
  forceNoindex?: boolean;
};

export const HeadSectionTags: FC<HeadSectionTagsProps> = ({
  pageName,
  data,
  forceNoindex,
}) => {
  const robotsContent =
    forceNoindex || !data?.indexable ? 'noindex, follow' : 'index, follow';

  return (
    <Helmet>
      <title>{data?.pageTitle}</title>
      {robotsContent ? <meta name="robots" content={robotsContent} /> : null}
      {data?.pageDescription ? (
        <meta name="description" content={data.pageDescription} />
      ) : null}
      <meta name="referrer" content="default" />
      {data?.pageCanonical ? (
        <link
          rel="canonical"
          href={setCanonical(data.pageCanonical, pageName)}
        />
      ) : null}
      <meta name="format-detection" content="telephone=no" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@XING_de" />
      {data?.socialMediaTitle ? (
        <meta name="twitter:title" content={data.socialMediaTitle} />
      ) : null}
      {data?.socialMediaDescription ? (
        <meta
          name="twitter:description"
          content={data.socialMediaDescription}
        />
      ) : null}
      {data?.socialMediaImage ? (
        <meta name="twitter:image" content={data.socialMediaImage} />
      ) : null}

      {/* OG tags */}
      {data?.socialMediaTitle ? (
        <meta property="og:title" content={data.socialMediaTitle} />
      ) : null}
      {data?.pageCanonical ? (
        <meta property="og:url" content={data.pageCanonical} />
      ) : null}
      {data?.socialMediaImage ? (
        <meta property="og:image" content={data.socialMediaImage} />
      ) : null}
      {data?.socialMediaDescription ? (
        <meta property="og:description" content={data.socialMediaDescription} />
      ) : null}
      <meta property="og:site_name" content="XING" />
      <meta property="og:type" content="profile" />

      {/* Open search */}
      <link
        rel="search"
        type="application/opensearchdescription+xml"
        title="XING"
        href="/media/xing-opensearch.xml"
      />
    </Helmet>
  );
};
