import styled from 'styled-components';

import { spaceL, spaceXL, spaceXXL } from '@xing-com/tokens';
import { Grid, Row } from '@xing-com/xing-grid';

export const StyledGrid = styled(Grid)`
  padding-left: ${spaceXXL};
  padding-top: ${spaceXXL};
  padding-bottom: ${spaceXXL};
  padding-right: ${spaceXL};
`;

export const StyledRow = styled(Row)`
  margin-top: ${spaceL};
`;
