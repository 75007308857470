import { useCallback, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { IconCross, IconInfo } from '@xing-com/icons';
import { Section } from '@xing-com/layout-primitives';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import {
  scale170,
  scale210,
  spaceL,
  xdlColorInfoSoft,
  xdlColorText,
} from '@xing-com/tokens';

const StyledTopBarPlacebo = styled.div`
  height: 0;
  @media ${mediaTopBarVisible} {
    height: ${scale170};
  }
`;

const StyledSection = styled(Section)`
  position: fixed;
  background-color: ${xdlColorInfoSoft};
  color: ${xdlColorText};

  top: 0;
  left: 0;
  right: 0;
  z-index: 300; /* more than header */

  /* Height as in packages/layout/header/src/header.styles.ts */
  height: ${scale210};
  @media ${mediaTopBarVisible} {
    height: ${scale170};
  }

  display: flex;
  gap: ${spaceL};
  align-items: center;
`;

const StyledText = styled.div`
  flex-grow: 1;
`;

export const ProfilePreviewHeader: FC = () => {
  const { track } = useTracking<{
    PropTrackAction: string;
  }>();

  const onClick = useCallback(() => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'profile_self_preview_header_close',
    });
  }, [track]);

  return (
    <>
      <StyledTopBarPlacebo />
      <StyledSection noBlockPadding>
        <IconInfo width={24} height={24} />
        <StyledText>
          <FormattedMessage id="PROFILE_LO_PREVIEW_INFO_BANNER" />
        </StyledText>
        <TextButton
          to="/profile"
          onClick={onClick}
          icon={IconCross}
          data-qa="profile-preview-close-button"
        >
          <FormattedMessage id="PROFILE_LO_PREVIEW_CLOSE_PREVIEW" />
        </TextButton>
      </StyledSection>
    </>
  );
};
