import type { PropsWithChildren } from 'react';
import { type FC, type MouseEvent } from 'react';

import { useTrackingParamWithContext } from '../../helpers';

type TrackingLinkProps = {
  className?: string;
  href: string;
  title?: string;
  trackingParamWithoutPrefix: string;
};

// Should be a simplified when https://new-work.atlassian.net/browse/WEB-2680 is done
export const TrackingLink: FC<PropsWithChildren<TrackingLinkProps>> = ({
  children,
  className,
  href,
  title,
  trackingParamWithoutPrefix,
}) => {
  const trackingParam = useTrackingParamWithContext(trackingParamWithoutPrefix);
  const hrefWithTracking = `${href}?sc_o=${trackingParam}`;

  const openLinkWithTrackingParam = (e: MouseEvent): void => {
    e.preventDefault();
    window.location.href = hrefWithTracking;
  };

  const addTrackingParamToLink = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    e.currentTarget.href = hrefWithTracking;
  };

  const removeTrackingParamFromLink = (
    e: MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();
    e.currentTarget.href = href;
  };

  return (
    <a
      className={className}
      href={href}
      onClick={openLinkWithTrackingParam}
      onMouseDown={addTrackingParamToLink}
      onMouseLeave={removeTrackingParamFromLink}
      title={title}
    >
      {children}
    </a>
  );
};
