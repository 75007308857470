export const formatString = (localizedTimeString: string): React.ReactNode => {
  const parts = localizedTimeString.split(/\*\*(.*?)\*\*/g);

  return (
    <>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return <strong key={index}>{part}</strong>;
        }
        return <span key={index}>{part}</span>;
      })}
    </>
  );
};
