const keysMap: Record<string, string> = {
  type: '@type',
  context: '@context',
};

/**
 * Replaces keys to bring back the "@", removes empty entries and __typename.
 *
 * Probably it would be better to just return the JSON from the backend as a
 * string without validating it against the schema and pass it on as a black
 * box, but for now we need this.
 */
export const cleanStructuredData = (object: object): object => {
  if (typeof object !== 'object') return object;
  if (Object.keys(object).length === 0) return object;

  return Object.fromEntries(
    Object.entries(object).flatMap(([key, value]) => {
      if (!value) return [];
      if (key === '__typename') return [];

      const newValue = Array.isArray(value)
        ? value.filter(Boolean).map((v) => cleanStructuredData(v))
        : cleanStructuredData(value);
      return [[keysMap[key] || key, newValue]];
    })
  );
};
