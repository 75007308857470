import styled from 'styled-components';

import { Container as LayoutPrimitivesContainter } from '@xing-com/layout-primitives';
import {
  spaceL,
  spaceXL,
  spaceXS,
  spaceXXL,
  xdlColorBorderStrong,
} from '@xing-com/tokens';

export const Container = styled.div`
  &:not(:first-child) {
    margin-top: ${spaceXXL};
  }
`;

export const EntriesList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const CTA = styled(LayoutPrimitivesContainter)`
  position: relative;

  &::before {
    background-color: ${xdlColorBorderStrong};
    bottom: 0;
    content: '';
    display: block;
    margin-bottom: ${spaceXL};
    margin-top: ${spaceXL};
    position: absolute;
    top: 0;
    width: ${spaceXS};
    /* remove following code when link is used with headline */
    margin-bottom: ${spaceL};
    margin-top: ${spaceL};
  }
`;
