import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM } from '@xing-com/tokens';

export const ReportButton = styled(Button)`
  @media ${mediaConfined} {
    margin-left: ${spaceM};
    vertical-align: middle;
  }
`;

export const MenuItemText = styled.span<{ $isLoading: boolean }>`
  ${({ $isLoading }) =>
    $isLoading
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ``}
`;

export const MenuItemDotLoadingIndicator = styled(DotLoadingIndicator)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

  @media ${mediaConfined} {
    right: 0;
    left: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
