//import type { THeaderImages } from '../../types';
import type { HeaderImage } from '@xing-com/crate-common-graphql-types';

import basicPng375 from './basic_375.png';
import basicWebp375 from './basic_375.webp';
import basicPng750 from './basic_750.png';
import basicWebp750 from './basic_750.webp';
import basicPng984 from './basic_984.png';
import basicWebp984 from './basic_984.webp';
import premiumPng375 from './premium_375.png';
import premiumWebp375 from './premium_375.webp';
import premiumPng750 from './premium_750.png';
import premiumWebp750 from './premium_750.webp';
import premiumPng984 from './premium_984.png';
import premiumWebp984 from './premium_984.webp';

export type TUserType = 'basic' | 'premium';
export type TImageType = 'webp' | 'png';

export type TCovers = {
  [key in TUserType]: {
    [key in TImageType]: Array<HeaderImage>;
  };
};

export const covers: TCovers = {
  basic: {
    webp: [
      {
        url: basicWebp984,
        reference: '984',
      },
      {
        url: basicWebp750,
        reference: '750',
      },
      {
        url: basicWebp375,
        reference: '375',
      },
    ],
    png: [
      {
        url: basicPng984,
        reference: '984',
      },
      {
        url: basicPng750,
        reference: '750',
      },
      {
        url: basicPng375,
        reference: '375',
      },
    ],
  },
  premium: {
    webp: [
      {
        url: premiumWebp984,
        reference: '984',
      },
      {
        url: premiumWebp750,
        reference: '750',
      },
      {
        url: premiumWebp375,
        reference: '375',
      },
    ],
    png: [
      {
        url: premiumPng984,
        reference: '984',
      },
      {
        url: premiumPng750,
        reference: '750',
      },
      {
        url: premiumPng375,
        reference: '375',
      },
    ],
  },
};
