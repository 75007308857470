import type React from 'react';

import type { RegistrationFormProps } from '@xing-com/crate-login-types';
import { useHost } from '@xing-com/crate-xinglet';

export const RegistrationForm: React.FC<RegistrationFormProps> = (props) => {
  const host = useHost();

  return (
    <host.XingletLoader
      {...props}
      name="@xing-com/crate-login-registration-form"
    />
  );
};
