import type { FC } from 'react';
import type React from 'react';
import styled, { css } from 'styled-components';

import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { spaceM } from '@xing-com/tokens';

import { useLazyLoad } from '../../helpers';

const StyledSuperEllipse = styled(SuperEllipse)<{
  $hasHref?: boolean;
  $hideOnHandheld?: boolean;
  $hideOnTabletAndDesktop?: boolean;
}>`
  float: right;

  @media ${mediaSmallOrTiny} {
    margin-left: ${spaceM};
  }

  ${({ $hasHref }) =>
    $hasHref &&
    css`
      cursor: pointer;
    `}

  ${({ $hideOnHandheld }) =>
    $hideOnHandheld &&
    css`
      {
        @media ${mediaSmallOrTiny} {
          display: none;
        }
      }
    `}

  ${({ $hideOnTabletAndDesktop }) =>
    $hideOnTabletAndDesktop &&
    css`
      {
        @media ${mediaConfined} {
          display: none;
        }
      }
    `}
`;

type LogoProps = {
  href?: string;
  hideOnHandheld?: boolean;
  hideOnTabletAndDesktop?: boolean;
} & React.HTMLProps<HTMLImageElement>;

export const Logo: FC<LogoProps> = ({
  src,
  href,
  alt,
  hideOnHandheld,
  hideOnTabletAndDesktop,
}) => {
  const lazyLoadedSrc = useLazyLoad(src) || undefined;

  if (!src) {
    return null;
  }

  const onClick = (): void => {
    if (href) {
      window.location.assign(href);
    }
  };

  return (
    <StyledSuperEllipse
      size="medium"
      sizeConfined="large"
      src={lazyLoadedSrc}
      onClick={onClick}
      type="company"
      profileName={alt}
      $hasHref={!!href}
      $hideOnHandheld={hideOnHandheld}
      $hideOnTabletAndDesktop={hideOnTabletAndDesktop}
    />
  );
};
