import find from 'lodash/find';
import get from 'lodash/get';
import type { FC } from 'react';

import type { XingIdOccupation } from '@xing-com/crate-common-graphql-types';
// eslint-disable-next-line node/no-unpublished-import
import type { ProfileLoProfileQuery } from '@xing-com/crate-profile-main-loader-logged-out';

interface IProps {
  xingId: NonNullable<
    Exclude<
      ProfileLoProfileQuery['xingIdWithError'],
      { __typename: 'ResponseError' }
    >
  >;
}

function findRelevantOccupation(
  occupations: Array<XingIdOccupation | null>
): XingIdOccupation | null {
  const relevantOccupation = find(
    occupations,
    (occupation: XingIdOccupation) => {
      return (
        occupation.category === 'EDUCATION' ||
        occupation.category === 'WORK_EXPERIENCE'
      );
    }
  );
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (relevantOccupation as XingIdOccupation) || null;
}

export const HCardMicrodata: FC<IProps> = ({ xingId }) => {
  const imageUrl = get(xingId, 'profileImage.0.url', '');
  const city = get(xingId, 'location.city', null);
  const occupation =
    xingId.occupations && findRelevantOccupation(xingId.occupations);
  return (
    <div className="vcard" style={{ display: 'none' }}>
      <div className="fn">{xingId.displayName}</div>
      {occupation && (
        <>
          <div className="title">{occupation.headline}</div>
          <div className="org">{occupation.subline}</div>
        </>
      )}
      {city && <div className="adr">{city}</div>}
      <img className="photo" src={imageUrl} alt={xingId.displayName} />
    </div>
  );
};
