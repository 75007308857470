import styled from 'styled-components';

import { Carousel } from '@xing-com/carousel';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';

export const RelatedProfileCarousel = styled(Carousel)`
  overflow-wrap: anywhere;
  hyphens: auto;

  & > * {
    width: auto;
    flex-basis: 0;
  }
  @media ${mediaTopBarVisible} {
    & > :first-child {
      margin-inline-start: ${spaceL};
    }
  }
`;
