import type { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale150, spaceS, spaceXS, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const StyledListItem = styled.li`
  margin: ${spaceXXL} auto 0;
  display: flex;
  align-items: flex-start;
  text-align: left;

  @media ${mediaSmallOrTiny} {
    max-width: 360px;
  }

  @media ${mediaConfined} {
    margin-top: ${scale150};
  }
`;

const StyledHeadline = styled(BodyCopy)`
  margin: 0 0 ${spaceXS};

  @media ${mediaConfined} {
    margin: 0 0 ${spaceS};
  }
`;

export const BenefitsListItem: FunctionComponent<{
  translationId: string;
}> = ({ translationId }) => {
  return (
    <StyledListItem>
      <div>
        <StyledHeadline fontWeight="bold" size="small" sizeConfined="xlarge">
          <FormattedMessage id={`PROFILE_LO_FE_${translationId}_TITLE`} />
        </StyledHeadline>
        <BodyCopy size="small" sizeConfined="medium" noMargin>
          <FormattedMessage id={`PROFILE_LO_FE_${translationId}_TEXT`} />
        </BodyCopy>
      </div>
    </StyledListItem>
  );
};
