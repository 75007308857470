import type { FC } from 'react';

import type { UserFlagsDisplayFlag } from '@xing-com/crate-common-graphql-types';

import { covers } from '../assets';
import type { TImageType, TUserType } from '../assets';
import { buildSrcSet, imageSizes } from '../helpers';
import { ImageTag } from '../image-tag';

type TLowercaseDisplayFlag = Lowercase<UserFlagsDisplayFlag>;

export const DefaultImage: FC<{ displayFlag: UserFlagsDisplayFlag | null }> = ({
  displayFlag,
}) => {
  if (!displayFlag) return null;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const rawUserType = displayFlag.toLowerCase() as TLowercaseDisplayFlag;
  const userType: TUserType = rawUserType !== 'basic' ? 'premium' : 'basic';
  const imagesByUserType = covers[userType];
  const imageTypes: TImageType[] = ['webp', 'png'];
  const srcSets: { [key in TImageType]: string } = {
    webp: buildSrcSet(imagesByUserType['webp']),
    png: buildSrcSet(imagesByUserType['png']),
  };

  return (
    <picture>
      {imageTypes.map((imgType) => (
        <source
          key={imgType}
          srcSet={srcSets[imgType]}
          type={`image/${imgType}`}
          sizes={imageSizes}
        />
      ))}
      <ImageTag
        src={imagesByUserType.png[0].url}
        dataQa="default-background-image"
      />
    </picture>
  );
};
