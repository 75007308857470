import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import styled, { css } from 'styled-components';

import { spaceL, spaceXS } from '@xing-com/tokens';

import { Tag } from '../tag';

const TagsContainer = styled.div<{ $highlighted: boolean }>`
  ${({ $highlighted }) =>
    $highlighted
      ? css`
          & {
            margin-top: calc(${spaceL} + ${spaceXS});
          }
        `
      : null}
`;

export interface IProps {
  tags: Array<string>;
  highlighted?: boolean;
  tagDataQa?: string;
}

export const TagsList: FC<IProps> = ({ tags, highlighted, tagDataQa }) => {
  if (isEmpty(tags)) {
    return null;
  }

  return (
    <TagsContainer $highlighted={!highlighted}>
      {tags.map((tag) => (
        <Tag highlighted={highlighted} key={tag}>
          <div data-qa={tagDataQa}>{tag}</div>
        </Tag>
      ))}
    </TagsContainer>
  );
};
