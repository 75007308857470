import styled, { css } from 'styled-components';

import { Hyperlink } from '@xing-com/hyperlink';
import {
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
  xdlColorHighlighted,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const baseOrganizationStyles = css`
  line-height: 1.5;
  display: inline;
`;

export const StyledLi = styled.li`
  background-color: transparent;
  border: none;
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
`;

export const MarkCurrent = styled.span`
  background-color: ${xdlColorHighlighted};
  bottom: 0;
  display: block;
  position: absolute;
  top: 0;
  margin-bottom: ${spaceXL};
  margin-top: ${spaceXL};
  width: ${spaceXS};
`;

export const StyledHyperlink = styled(Hyperlink)`
  ${baseOrganizationStyles}
`;

export const StyledBodyCopy = styled(BodyCopy)`
  ${baseOrganizationStyles}
`;

export const Time = styled.div`
  /*
  Time row now is the top one. It has less line height then the bottom line.
  So I just put 2px here to make it look similar.
  We can remove it when occupation will be top line of the entry.
  */
  margin-bottom: ${spaceM};
  margin-top: 2px;

  p {
    margin: 0;
  }
`;

export const CurrentSign = styled.span`
  font-weight: bold;
  margin-right: ${spaceS};
`;

export const Occupation = styled.div`
  h4 {
    margin-bottom: ${spaceS};
  }
`;
