import styled from 'styled-components';

import { Container } from '@xing-com/layout-primitives';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  space3XL,
  spaceS,
  spaceXXL,
  xdlColorProJobsTertiary,
} from '@xing-com/tokens';

export const StyledBenefitsSection = styled.div`
  text-align: center;

  @media ${mediaConfined} {
    padding-top: calc(${spaceXXL} + ${spaceS});
    padding-bottom: calc(${spaceXXL} + ${spaceS});
  }
`;

export const StyledBenefitsContainer = styled(Container)`
  background-color: ${xdlColorProJobsTertiary};
  padding-top: calc(${spaceXXL} + ${spaceS});
  padding-bottom: calc(${spaceXXL} + ${spaceS});
`;

export const StyledBenefitsList = styled.ul`
  padding: 0;
  margin: 0 auto 0;
  @media ${mediaConfined} {
    width: 65%;
  }
`;

export const StyledBenefitsCtaContainer = styled.div`
  text-align: center;
  margin-top: ${spaceXXL};

  @media ${mediaConfined} {
    margin-top: ${space3XL};
  }
`;
