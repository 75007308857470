import styled from 'styled-components';

import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceL, spaceS, spaceXXL } from '@xing-com/tokens';

export const Container = styled.div`
  padding-bottom: ${spaceXXL};
  padding-top: ${spaceXXL};
`;

export const ModuleHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${spaceL};
  margin-top: 0;

  @media ${mediaConfined} {
    margin-bottom: ${spaceXXL};
  }
`;

export const AfterHeadline = styled.div`
  flex-shrink: 0;
  max-width: 100%;

  @media ${mediaSmallOrTiny} {
    width: 100%;
    margin-top: ${spaceS};
    margin-bottom: ${spaceS};
  }
`;
