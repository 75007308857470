import type { FC } from 'react';
import type React from 'react';
import styled from 'styled-components';

import { imageSizes } from '../helpers';

const StyledImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-size: cover;
  background-position: center;
  user-select: none;
`;

interface IProps extends React.HTMLProps<HTMLImageElement> {
  dataQa?: string;
}

export const ImageTag: FC<IProps> = (props) => {
  const { src, srcSet, dataQa } = props;

  return (
    <StyledImg
      alt=""
      src={src}
      srcSet={srcSet}
      sizes={imageSizes}
      data-qa={dataQa}
    />
  );
};
