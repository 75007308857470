import styled, { css } from 'styled-components';

import { IconButterflyMono, IconCampus, IconJobs } from '@xing-com/icons';

const baseIcon = css`
  top: 1px;
  left: 0;
  position: absolute;
`;

const defaultIcon = css`
  background-color: currentColor;
  border-radius: 50%;
  height: 14px;
  left: 2px;
  position: absolute;
  top: 7px;
  width: 14px;
`;

export const StyledIconCampus = styled(IconCampus)`
  ${baseIcon};
`;

export const StyledIconButterflyMono = styled(IconButterflyMono)`
  ${baseIcon};
`;

export const StyledIconJobs = styled(IconJobs)`
  ${baseIcon};
`;

export const IconInactive = styled.span`
  ${defaultIcon};

  &::after {
    background-color: white;
    border-radius: 50%;
    content: '';
    height: 8px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 8px;
  }
`;

export const IconOther = styled.span`
  ${defaultIcon};
`;
