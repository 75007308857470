import type { FC } from 'react';
import styled from 'styled-components';

import { spaceXS } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const StyledBodyCopy = styled(BodyCopy)`
  && {
    margin-top: ${spaceXS};
    margin-bottom: 0;
  }
`;

interface IProps {
  status: string;
}

export const StatusMessage: FC<IProps> = ({ status }) => {
  return (
    <StyledBodyCopy size="small" sizeConfined="medium" fontWeight="bold">
      {' '}
      {status}{' '}
    </StyledBodyCopy>
  );
};
