import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import type { RelatedProfile } from '@xing-com/crate-common-graphql-types';
import { getProfileRouteWithIdLo } from '@xing-com/crate-profile-helpers';
import { ProfileImage } from '@xing-com/profile-image';
import { BodyCopy, Meta } from '@xing-com/typography';
import { UserFlag } from '@xing-com/user-flag';
import type { displayFlagType } from '@xing-com/user-flag/lib/user-flag';

import { useLazyLoad } from '../../helpers';
import { ProfileSimilarProfilesUserFlagDocument } from './profileSimilarProfilesUserFlag.gql-types';
import {
  StyledCard,
  StyledFlagContainer,
  StyledHeadline,
  StyledProfileInfo,
  StyledTrackingLink,
} from './related-profile-card.styles';

type RelatedProfileCardProps = {
  otherProfile: RelatedProfile;
  trackingParamWithoutPrefix: string;
};

export const RelatedProfileCard: FC<RelatedProfileCardProps> = ({
  otherProfile,
  trackingParamWithoutPrefix,
}) => {
  const {
    photoUrl,
    firstName,
    lastName,
    pageName,
    occupationTitle,
    businessCity,
    id,
  } = otherProfile;
  const displayName = `${firstName} ${lastName}`;

  const photoUrlWithSize = `${photoUrl}.128x128.jpg?similarprofile=true`;
  const lazyLoadedProfileImage = useLazyLoad(photoUrlWithSize) || undefined;

  const { data } = useQuery(ProfileSimilarProfilesUserFlagDocument, {
    variables: { id: id || '(should be skipped)' },
    errorPolicy: 'all',
    ssr: false,
    skip: !id,
  });

  const displayFlag = useLazyLoad(data?.userFlags?.displayFlag);

  return (
    <StyledCard>
      <StyledTrackingLink
        href={getProfileRouteWithIdLo(pageName || '')}
        title={displayName}
        trackingParamWithoutPrefix={trackingParamWithoutPrefix}
      >
        <StyledProfileInfo
          alignment="vertical"
          profileImage={
            <ProfileImage
              size="xlarge"
              profileName={displayName}
              src={lazyLoadedProfileImage}
            />
          }
        >
          {displayFlag && pageName ? (
            <StyledFlagContainer>
              <UserFlag
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                displayFlag={displayFlag as displayFlagType}
                showBasicFlag
                userId={pageName}
                size="medium"
              />
            </StyledFlagContainer>
          ) : null}
          <StyledHeadline size="small">{displayName}</StyledHeadline>

          <BodyCopy size="xsmall">{occupationTitle}</BodyCopy>
          <Meta size="xsmall">{businessCity}</Meta>
        </StyledProfileInfo>
      </StyledTrackingLink>
    </StyledCard>
  );
};
