import type { FC } from 'react';

import type { Xinglet } from '@xing-com/crate-xinglet';

import type { ProfileViewProps } from './profile-view';
import { ProfileView } from './profile-view';

export default class MainLoggedOut implements Xinglet {
  public getComponent(): FC<ProfileViewProps> {
    return ProfileView;
  }
}
