import type { FC, PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { StatusBanner } from '@xing-com/banner';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';

import { CtaBannerContext } from './cta-banner-context';

export const CtaBannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isPreviewBannerVisible, setIsPreviewBannerVisible] = useState(false);

  const triggerBanner = useCallback(() => {
    setIsPreviewBannerVisible((value) => {
      // Re-pop-up the banner if it was already visible:
      if (value) {
        setTimeout(() => {
          setIsPreviewBannerVisible(true);
        }, 1);
      }

      return !value;
    });
  }, []);

  return (
    <CtaBannerContext.Provider value={triggerBanner}>
      {children}
      <NoSSR>
        <StatusBanner
          variant="error"
          show={isPreviewBannerVisible}
          handleOnClose={() => {
            setIsPreviewBannerVisible(false);
          }}
          data-qa="preview_banner_ALREADY_LOGGED_IN"
        >
          <FormattedMessage id="PROFILE_LO_PREVIEW_ALREADY_LOGGED_IN_V2" />
        </StatusBanner>
      </NoSSR>
    </CtaBannerContext.Provider>
  );
};
