import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { H } from '@xing-com/typography';

import {
  Level,
  LevelIndicator,
  StyledBodyCopy,
  StyledLi,
} from './language-snippet.styles';

export type LanguageSnippetProps = {
  languageName: string;
  skillLevelId: number;
};

const SkillLevelTitle: FC<{ level: number }> = ({ level }) =>
  !level ? (
    <StyledBodyCopy
      className="levelTitleEmpty"
      size="small"
      sizeConfined="medium"
    >
      -
    </StyledBodyCopy>
  ) : (
    <StyledBodyCopy size="small" sizeConfined="medium">
      <FormattedMessage id={`PROFILE_LO_FE_LANGUAGE_SKILLS_LEVEL_${level}`} />
    </StyledBodyCopy>
  );

export const LanguageSnippet: FC<LanguageSnippetProps> = ({
  languageName,
  skillLevelId,
}) => {
  return (
    <StyledLi>
      <H as="h3" className="title">
        {languageName}
      </H>
      <Level>
        {Boolean(skillLevelId) && (
          <LevelIndicator style={{ width: `${20 + 20 * skillLevelId}%` }} />
        )}
        <SkillLevelTitle level={skillLevelId} />
      </Level>
    </StyledLi>
  );
};
