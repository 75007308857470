import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { cleanStructuredData } from './clean-structured-data';

type StructuredDataProps = {
  data: { type?: string | null } | null | undefined;
};

export const StructuredData: FC<StructuredDataProps> = ({ data }) => {
  if (!data?.type) return null;

  const formattedStructureData = cleanStructuredData(data);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(formattedStructureData)}
      </script>
    </Helmet>
  );
};
