import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { ProfileInfo } from '@xing-com/profile-info';
import { spaceM, spaceS } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

import { TrackingLink } from '../tracking-link';

export const StyledCard = styled(Card)`
  padding: ${spaceS};
  min-height: 100%;
  width: 100%;

  @media ${mediaConfined} {
    border: none !important;
    padding: 0 !important;
  }
`;

export const StyledProfileInfo = styled(ProfileInfo)`
  display: block;

  @media ${mediaSmallOrTiny} {
    width: 122px;
  }
`;

export const StyledFlagContainer = styled.div`
  margin-top: ${spaceM};
`;

export const StyledHeadline = styled(Headline)`
  margin-top: ${spaceM};
  margin-bottom: 0;
`;

export const StyledTrackingLink = styled(TrackingLink)`
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;
