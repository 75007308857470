import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { PopUpWindow } from '@xing-com/pop-up-window';
import { BodyCopy, Hero } from '@xing-com/typography';

import signupLayerIllustration from './signup-layer-illustration.svg';

export const StyledPopUpWindow = styled(PopUpWindow)`
  & > div {
    width: 830px;
    padding: 68px;
  }
`;

export const StyledHero = styled(Hero)`
  margin-bottom: 8px;
`;

export const StyledSubHeadline = styled(BodyCopy)`
  margin-bottom: 40px;
`;

export const StyledFormContainer = styled.div`
  padding-right: 344px;
  background-image: url(${signupLayerIllustration});
  background-repeat: no-repeat;
  background-size: 254px;
  background-repeat: no-repeat;
  background-position: right center;
`;

export const StyledLoginButton = styled(TextButton)`
  display: block;
`;
