import styled from 'styled-components';

import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWide,
} from '@xing-com/layout-tokens';
import {
  scale070,
  scale090,
  spaceL,
  spaceS,
  spaceXS,
  spaceXXL,
  spaceXXS,
  xdlColorText,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const StyledContainer = styled.div`
  padding-top: calc(${spaceXXL} + ${spaceS});
  padding-bottom: calc(${spaceXXL} + ${spaceS});
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const StyledDirectoryContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${spaceL};
  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const StyledSubheader = styled(BodyCopy)`
  @media ${mediaConfined} {
    padding-right: ${spaceS};
  }
`;

export const StyledAlphabetsContainer = styled.div`
  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceS};
  }

  @media ${mediaWide} {
    padding-top: 1px;
  }

  a {
    color: ${xdlColorText};
    display: inline-flex;
    align-items: center;
    padding-right: calc(${spaceXS} + ${spaceXXS});
    font-weight: bold;
    font-size: ${scale090};
    line-height: 1;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    @media ${mediaWide} {
      padding-right: ${spaceXS};
      font-size: ${scale070};
    }
  }
`;
