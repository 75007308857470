import styled, { css } from 'styled-components';

import { IconArrowRight } from '@xing-com/icons';
import { scale080, spaceXS, xdlColorText } from '@xing-com/tokens';

export const Container = styled.span<{ $isLarge: boolean; $bold: boolean }>`
  align-items: center;
  display: inline-flex;

  ${({ $isLarge }) =>
    $isLarge
      ? css`
          font-size: ${scale080};
        `
      : ``}

  ${({ $bold }) =>
    $bold
      ? css`
          font-weight: bold;
        `
      : ``}
`;

export const Link = styled.span`
  align-items: center;
  color: ${xdlColorText};
`;

export const IconContainer = styled.span`
  white-space: nowrap;
`;

export const StyledIconArrowRight = styled(IconArrowRight)`
  margin-bottom: 1px;
  margin-left: ${spaceXS};
  vertical-align: middle;
`;
