import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { Headline } from '@xing-com/typography';

import { useCtaLinkOnClick } from '../components/cta-link';
import { BenefitsListItem } from './benefits-list-item';
import {
  StyledBenefitsContainer,
  StyledBenefitsCtaContainer,
  StyledBenefitsList,
  StyledBenefitsSection,
} from './benefits.styles';

const listItemTranslationIds: string[] = [
  'BENEFITS_COMMUNITY',
  'BENEFITS_NETWORK',
  'BENEFITS_FREE',
  'MEMBERSHIP',
];

export const Benefits: FC<{ scrambledProfileUrl: string | null }> = ({
  scrambledProfileUrl,
}) => {
  const onClick = useCtaLinkOnClick({
    scrambledProfileUrl,
    trackingParamWithoutPrefix: 'upsell_benefits_element',
  });
  return (
    <StyledBenefitsSection>
      <StyledBenefitsContainer variant="regular">
        <Headline size="xxlarge" sizeConfined="xxxlarge">
          <FormattedMessage id="PROFILE_LO_FE_BENEFITS_HEADLINE" />
        </Headline>
        <StyledBenefitsList>
          {listItemTranslationIds.map((translationId) => (
            <BenefitsListItem
              key={translationId}
              translationId={translationId}
            />
          ))}
        </StyledBenefitsList>
        <StyledBenefitsCtaContainer>
          <Button variant="primary" size="medium" onClick={onClick}>
            <FormattedMessage id="PROFILE_LO_FE_BENEFITS_BUTTON_CTA" />
          </Button>
        </StyledBenefitsCtaContainer>
      </StyledBenefitsContainer>
    </StyledBenefitsSection>
  );
};
