import styled from 'styled-components';

import { IconArrowDown } from '@xing-com/icons';
import { spaceM, spaceS, xdlColorText } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledButton = styled.button`
  display: flex;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  align-items: center;
`;

export const StyledBodyCopy = styled(BodyCopy)`
  flex: 0 0 auto;
  margin-right: ${spaceS};
  margin-bottom: 0;
`;

export const Content = styled.div`
  padding-top: ${spaceM};
  margin: 0;
  word-wrap: break-word;
`;

export const StyledIcon = styled(IconArrowDown)<{ $isShow: boolean }>`
  color: ${xdlColorText};
  flex: 0 0 auto;
  transform: ${({ $isShow }) => ($isShow ? 'rotate(-180deg)' : `rotate(0deg)`)};
  transition: transform 0.2s linear;
`;
