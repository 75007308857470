import styled, { css } from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { Container, Section } from '@xing-com/layout-primitives';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWide,
} from '@xing-com/layout-tokens';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { spaceL, spaceM, spaceS, spaceXL, spaceXS } from '@xing-com/tokens';
import { Row } from '@xing-com/xing-grid';

import { SectionHeadline } from '../components/section-headline';

export const StyledSection = styled(Section)`
  @media ${mediaSmallOrTiny} {
    padding-block: 0;
    padding-inline: 0;
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
`;

export const PhotoRow = styled(Row)`
  position: relative;

  @media ${mediaConfined} {
    height: 0;
    top: 0;
  }
`;

export const PhotoContainer = styled.div`
  margin-bottom: ${spaceL};
  margin-top: -75%;

  @media ${mediaConfined} {
    height: 0;
    margin-top: -55%;
  }
`;

export const StyledCtaLink = styled(TextButton)`
  display: block;
  height: auto;
  width: 100%;
`;

export const StyledSuperEllipse = styled(SuperEllipse)`
  && {
    height: auto;
    width: 100%;
  }
`;

export const UserNameRow = styled(Row)`
  @media ${mediaConfined} {
    margin-top: calc(${spaceXS} + ${spaceL});
  }

  @media ${mediaWide} {
    margin-top: ${spaceXL};
  }
`;

export const UserNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const UserNameContainerText = styled.div`
  flex-grow: 1;
  margin-bottom: ${spaceL};

  @media ${mediaConfined} {
    margin-bottom: ${spaceL};
  }
`;

export const HeadlineContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledSectionHeadline = styled(SectionHeadline)`
  margin-bottom: ${spaceXS};
  margin-right: ${spaceS};
  margin-top: 0;
`;

export const UserFlagContainer = styled.div`
  margin-bottom: ${spaceXS};
`;

export const MainCtaButtonContainer = styled.div`
  text-align: center;
  padding-top: ${spaceXS};
  padding-bottom: calc(${spaceXS} + ${spaceL});
  flex-shrink: 0;

  @media ${mediaConfined} {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${spaceM};
  }
`;

const fullWidth = css`
  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  ${fullWidth};
`;

export const ReportLink = styled.div`
  position: absolute;
  top: calc(${spaceXS} + ${spaceL});
  right: calc(${spaceXS} + ${spaceL});

  @media ${mediaConfined} {
    position: relative;
    top: 0px;
    right: 0px;
    display: inline;
  }
`;

export const CtaContainer = styled.div`
  position: relative;
  padding-left: 6px;
`;
