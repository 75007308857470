import type { CardProps } from '..';
import * as Styled from './card-inspire.styles';
const { sizes, variants } = Styled;

export type CardInspireSizes = keyof typeof sizes;
export type CardInspireVariants = keyof typeof variants;

export type CardInspireProps = {
  /** The size of the card */
  size?: CardInspireSizes;
  /** The variant of the card */
  variant: CardInspireVariants;
} & Omit<CardProps, 'noPadding' | 'selected' | 'variant'>;
