import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import type { HeaderImage } from '@xing-com/crate-common-graphql-types';

import { buildSrcSet, imageSizes } from '../helpers';
import { ImageTag } from '../image-tag';

export const CustomImage: FC<{ headerImage?: Array<HeaderImage> | null }> = ({
  headerImage,
}) => {
  const defaultImage: string | undefined = headerImage?.[0]?.url;

  if (!defaultImage) return null;

  const srcSet =
    (defaultImage && headerImage && buildSrcSet(headerImage)) ?? undefined;
  const linkAttributes = {
    href: defaultImage,
    rel: 'preload',
    as: 'image',
    imagesrcset: srcSet,
    imagesizes: imageSizes,
  };

  return (
    <>
      <Helmet>
        <link {...linkAttributes} />
      </Helmet>
      <ImageTag
        src={defaultImage}
        srcSet={srcSet}
        dataQa="custom-background-image"
      />
    </>
  );
};
