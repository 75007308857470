import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StatusBannerAutoHide, StatusBanner } from '@xing-com/banner';
import { captureException, useFetch, useHost } from '@xing-com/crate-xinglet';
import { IconMore } from '@xing-com/icons';
import { Menu, SingleSelect } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';

import { useIsProfilePreviewPage } from '../../helpers';
import {
  MenuItemDotLoadingIndicator,
  MenuItemText,
  ReportButton,
} from './report-profile-link.styles';

export interface IReportLinkProps {
  id: string;
}

export const ReportProfileLink: FC<IReportLinkProps> = ({ id }) => {
  const { isPreview } = useHost();
  const intl = useIntl();

  const moreButtonLabel = intl.formatMessage({
    id: 'PROFILE_LO_XINGID_MORE',
  });

  const externalUrl = isPreview
    ? 'https://preview.xing.com'
    : 'https://www.xing.com';

  const reportUrl: string = externalUrl + '/complaints/api/reports';
  const { triggerRef, show, handleShow, handleHide } = usePopOver();
  const [isLoading, changeLoadingState] = useState<boolean>(false);
  const [isResponseHasError, setResponseError] = useState<boolean>(false);
  const fetchJson = useFetch();

  const isProfilePreviewPage = useIsProfilePreviewPage();
  const [isPreviewBannerVisible, setIsPreviewBannerVisible] = useState(false);

  const onLoadEnd = (): void => {
    handleHide();
    changeLoadingState(false);
  };

  const onClick = (): void => {
    if (isProfilePreviewPage) {
      setIsPreviewBannerVisible((value) => !value);
      return;
    }

    changeLoadingState(true);
    const newWindow = window.open();

    fetchJson(reportUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        report: {
          author_urn: 'urn:x-xing:users:user:' + id,
          target_urn: 'urn:x-xing:users:user:' + id,
        },
      }),
    })
      .then((response: Response) => {
        onLoadEnd();

        const headers: Headers = response.headers;
        const redirectLocation: string | null = headers.get('Location');

        if (!redirectLocation) {
          throw new Error('redirectLocation is not true');
        }

        if (newWindow) {
          newWindow.location.href = externalUrl + redirectLocation;
        }
      })
      .catch((e) => {
        onLoadEnd();
        newWindow && newWindow.close();
        setResponseError(true);
        captureException(e);
      });
  };

  return (
    <>
      <ReportButton
        aria-label={moreButtonLabel}
        icon={IconMore}
        ref={triggerRef}
        onClick={handleShow}
        variant="tertiary"
        data-qa="report-profile-more-button"
      />
      <StatusBannerAutoHide
        variant="error"
        show={isResponseHasError}
        timeout={3000}
        handleOnClose={() => setResponseError(false)}
        data-qa="report-profile-error-message"
      >
        <FormattedMessage id="PROFILE_ERROR_SOMETHING_WENT_WRONG" />
      </StatusBannerAutoHide>
      <StatusBanner
        variant="error"
        show={isPreviewBannerVisible}
        handleOnClose={() => {
          setIsPreviewBannerVisible(false);
        }}
        data-qa="preview_banner_CANT_REPORT"
      >
        <FormattedMessage id="PROFILE_LO_PREVIEW_CANT_REPORT" />
      </StatusBanner>
      <Menu
        onOutsideClick={handleHide}
        triggerRef={triggerRef}
        show={show}
        data-qa="report-profile-menu"
      >
        <SingleSelect
          onClick={onClick}
          data-qa="report-profile-report-button"
          role="menuitem"
        >
          <MenuItemText $isLoading={isLoading}>
            <FormattedMessage id="PROFILE_LO_REPORT_PROFILE_LINK" />
          </MenuItemText>
          {isLoading && <MenuItemDotLoadingIndicator />}
        </SingleSelect>
      </Menu>
    </>
  );
};
