import { createContext, useContext } from 'react';

const IsProfilePreviewPageContext = createContext<boolean>(false);

export const IsProfilePreviewPageProvider =
  IsProfilePreviewPageContext.Provider;

export const useIsProfilePreviewPage = (): boolean => {
  return useContext(IsProfilePreviewPageContext);
};
