import type { FC } from 'react';
import styled from 'styled-components';

import { scale130, spaceL } from '@xing-com/tokens';
import { H } from '@xing-com/typography';

export const Container = styled.div`
  .headline.headline {
    margin-bottom: 0;
    margin-right: ${spaceL};

    @media (min-width: 1113px) {
      font-size: ${scale130};
    }
  }
`;

type IProps = {
  children: React.ReactNode;
  className?: string;
  dataQa?: string;
};

export const SectionHeadline: FC<IProps> = ({
  dataQa,
  className = '',
  children,
}) => {
  return (
    <Container>
      <H size="xxlarge" className={`headline ${className}`} data-qa={dataQa}>
        {children}
      </H>
    </Container>
  );
};
