import type { FC } from 'react';
import styled from 'styled-components';

import { Container, Section } from '@xing-com/layout-primitives';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceS, spaceXS } from '@xing-com/tokens';

import { PageSection } from '../components/page-section';
import { LanguageSnippet } from './language-snippet/language-snippet';

const StyledUl = styled.ul`
  padding: ${spaceS} 0 0;
  margin: 0;

  @media ${mediaConfined} {
    display: table;
    border-collapse: separate;
    border-spacing: 0 ${spaceL};
    padding-top: ${spaceXS};
  }
`;

export type LanguageSkillsProps = {
  data: Array<{
    __typename?: 'LoggedOutProfileLanguageSkills';
    language?: string | null;
    skillLevelId?: number | null;
    displayLanguage?: string | null;
  } | null> | null;
};

export const LanguageSkills: FC<LanguageSkillsProps> = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  return (
    <PageSection
      headlineTranslationKey="PROFILE_LO_FE_LANGUAGE_SKILLS_HEADLINE"
      dataQa="language-skills-section"
      scrollTrackingId="LanguageModule"
    >
      <Section noBlockPadding>
        <Container>
          <StyledUl>
            {data.map((item) => {
              if (
                !item ||
                !item.language ||
                item.skillLevelId === null ||
                item.skillLevelId === undefined ||
                !item.displayLanguage
              ) {
                return null;
              }
              return (
                <LanguageSnippet
                  key={item.language}
                  languageName={item.displayLanguage}
                  skillLevelId={item.skillLevelId}
                />
              );
            })}
          </StyledUl>
        </Container>
      </Section>
    </PageSection>
  );
};
