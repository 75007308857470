import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import type {
  ITimelineEntryProps,
  IEducationEntry,
  IWorkExperienceEntry,
} from './types';

export const formatEducationEntryData = (
  entry: IEducationEntry
): ITimelineEntryProps | null => {
  if (isEmpty(entry)) {
    return null;
  }

  const {
    subject,
    schoolName,
    beginDate,
    endDate,
    schoolNotes,
    localizedTimeString,
    primarySchool,
  } = entry;

  return {
    isCurrent: primarySchool,
    organisationName: schoolName,
    occupation: subject,
    beginDate,
    endDate,
    localizedTimeString,
    description: schoolNotes,
  };
};

export const formatWorkExperienceEntryData = (
  entry: IWorkExperienceEntry
): ITimelineEntryProps | null => {
  if (isEmpty(entry)) {
    return null;
  }

  const {
    companyName,
    currentCompany,
    jobTitle,
    beginDate,
    endDate,
    localizedTimeString,
    companyNotes,
  } = entry;

  return {
    organisationName: companyName,
    isCurrent: currentCompany,
    occupation: jobTitle,
    beginDate,
    endDate,
    localizedTimeString,
    logo: get(entry, 'company.logos.logo256px', null),
    url:
      get(entry, 'company.links.default', null) ||
      get(entry, 'company.links.public', null),
    description: companyNotes,
  };
};
