import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import type { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PageSection } from '../components/page-section';
import { BucketWithEntries } from './bucket/bucket';
import {
  formatEducationEntryData,
  formatWorkExperienceEntryData,
} from './helpers';
import type { ITimelineEntryProps, ITimelineProps as IProps } from './types';

export const Timeline: FunctionComponent<IProps> = ({
  xingId,
  education,
  workExperience,
}) => {
  if (!xingId) return null;

  const workExperienceEntries: Array<ITimelineEntryProps | null> | null =
    workExperience
      ? compact(workExperience).map(formatWorkExperienceEntryData)
      : null;

  const educationEntries: Array<ITimelineEntryProps | null> | null = education
    ? compact(education).map(formatEducationEntryData)
    : null;

  if (isEmpty(workExperienceEntries) && isEmpty(educationEntries)) return null;

  const { firstName, lastName, scrambledProfileUrl } = xingId;

  const workExperienceHeadlineTranslation = (
    <FormattedMessage
      id="PROFILE_LO_FE_TIMELINE_WORK_EXPERIENCE_HEADLINE"
      values={{ firstName, lastName }}
    />
  );

  const educationHeadlineTranslation = (
    <FormattedMessage
      id="PROFILE_LO_FE_TIMELINE_EDUCATION_HEADLINE"
      values={{ firstName, lastName }}
    />
  );

  return (
    <PageSection
      headlineTranslationKey="PROFILE_LO_FE_TIMELINE_HEADLINE"
      dataQa="timeline-section"
    >
      <BucketWithEntries
        headline={workExperienceHeadlineTranslation}
        entries={workExperienceEntries}
        ctaTranslationKey="PROFILE_LO_FE_UNFENCED_CTA"
        trackingParamWithoutPrefix="upsell_cv_timeline"
        sectionCtaDataQa="work-experience-section-cta"
        entryDataQa="we-entry"
        scrollTrackingId="ExperienceModule"
        scrambledProfileUrl={scrambledProfileUrl}
      />

      <BucketWithEntries
        headline={educationHeadlineTranslation}
        entries={educationEntries}
        ctaTranslationKey="PROFILE_LO_FE_UNFENCED_CTA"
        trackingParamWithoutPrefix="upsell_cv_timeline"
        sectionCtaDataQa="education-section-cta"
        entryDataQa="education-entry"
        scrollTrackingId="EducationModule"
        scrambledProfileUrl={scrambledProfileUrl}
      />
    </PageSection>
  );
};
