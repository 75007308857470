import type { FC } from 'react';
import styled from 'styled-components';

import { IconLocationPin } from '@xing-com/icons';
import {
  scale080,
  spaceL,
  spaceS,
  spaceXS,
  spaceXXL,
  spaceXXS,
} from '@xing-com/tokens';
import { Column, Row } from '@xing-com/xing-grid';

import { formatString } from '../../helpers';

const LocationContainer = styled.div`
  padding-bottom: ${spaceL};
  padding-left: calc(${spaceXXL} + ${spaceXXS});
  padding-right: ${spaceXS};
  padding-top: ${spaceXS};
  position: relative;
  font-size: ${scale080};
`;

const StyledIconLocationPin = styled(IconLocationPin)`
  left: 0;
  margin-right: ${spaceS};
  position: absolute;
  top: 1px;
`;

const LocationText = styled.div`
  p {
    margin: 0;
    font-size: inherit;
  }
`;

export interface IProps {
  location: string;
}

export const Location: FC<IProps> = ({ location }) => {
  return (
    <Row data-qa="xing-id-location">
      <Column
        offsetDesktop={3}
        offsetTablet={3}
        size={12}
        sizeDesktop={7}
        sizeTablet={9}
      >
        <LocationContainer>
          <StyledIconLocationPin width={24} height={24} />
          <LocationText>{formatString(location)}</LocationText>
        </LocationContainer>
      </Column>
    </Row>
  );
};
