import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import type { FC, ReactNode } from 'react';

import { Column, Row } from '@xing-com/xing-grid';

import { formatString } from '../../helpers';
import { Icon } from './icon';
import { OccupationContainer, OccupationText } from './occupations.styles';
import type { TCategory } from './types';

export interface IOccupation {
  category: TCategory;
  summary: string;
  links?: Array<{
    urn: string;
    url: string;
  } | null> | null;
  headline: string;
  subline: string;
}

export interface IProps {
  data: Array<IOccupation | null>;
}

const filterOccupationsForXingId = (
  occupations: Array<IOccupation | null>
): Array<IOccupation> => {
  const experiencesGroupedByEducation = groupBy(
    compact(occupations),
    ({ category }) => {
      return category === 'EDUCATION' ? 'education' : 'other';
    }
  );
  const workExperience =
    experiencesGroupedByEducation.other &&
    experiencesGroupedByEducation.other[0];
  const educationExperience =
    experiencesGroupedByEducation.education &&
    experiencesGroupedByEducation.education[0];
  return compact([workExperience, educationExperience]);
};

const Occupation: FC<IOccupation> = ({ summary, links, category }) => {
  const hasLinks = !isEmpty(links);

  const summaryFormatter = (summary: string): ReactNode | string => {
    if (hasLinks && links && links[0]) {
      const linkPattern = /\[([^\]]+)\]/;
      const linkMatch = summary.match(linkPattern);
      if (!linkMatch) {
        return formatString(summary);
      }
      const companyName = linkMatch[1];
      return (
        <>
          {summary.split(linkMatch[0])[0]}
          <a href={links[0].url}>{companyName}</a>
        </>
      );
    }

    return formatString(summary);
  };

  return (
    <OccupationContainer data-qa={`xing-id-${category.toLowerCase()}`}>
      <Icon category={category} />
      <OccupationText>{summaryFormatter(summary)}</OccupationText>
    </OccupationContainer>
  );
};

export const Occupations: FC<IProps> = ({ data }) => {
  const filteredOccupations = filterOccupationsForXingId(data);
  return isEmpty(filteredOccupations) ? null : (
    <Row data-qa="xing-id-container">
      <Column
        offsetDesktop={3}
        offsetTablet={3}
        size={12}
        sizeDesktop={7}
        sizeTablet={9}
      >
        {filteredOccupations.map((item, i) => (
          <Occupation key={i} {...item} />
        ))}
      </Column>
    </Row>
  );
};
