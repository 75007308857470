import type { FC } from 'react';

import type { TCategory } from '../types';
import {
  IconInactive,
  IconOther,
  StyledIconButterflyMono,
  StyledIconCampus,
  StyledIconJobs,
} from './icon.styles';

interface IProps {
  category: TCategory;
}

export const Icon: FC<IProps> = ({ category }) => {
  switch (category) {
    case 'EDUCATION':
      return (
        <StyledIconCampus width={24} height={24} data-qa="education-icon" />
      );

    case 'ROLE':
      return (
        <StyledIconButterflyMono width={24} height={24} data-qa="role-icon" />
      );

    case 'WORK_EXPERIENCE':
      return (
        <StyledIconJobs width={24} height={24} data-qa="work-experience-icon" />
      );

    case 'INACTIVE':
      return <IconInactive data-qa="inactive-icon" />;

    case 'OTHER':
    default:
      return <IconOther />;
  }
};
