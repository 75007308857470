import type { FC } from 'react';
import styled from 'styled-components';

import { spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledBodyCopy = styled(BodyCopy)`
  margin-bottom: ${spaceL};
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

interface IBottomProps {
  description: string;
}

export const BottomDescription: FC<IBottomProps> = ({ description }) => (
  <StyledBodyCopy size="small">{description}</StyledBodyCopy>
);
