import * as React from 'react';

import * as Styled from './hyperlink.styles';
import type { HyperlinkProps } from './hyperlink.types';

export const Hyperlink = React.forwardRef<HTMLLinkElement, HyperlinkProps>(
  (
    { children, className, external, href, to, ...props },
    forwardedRef
  ): JSX.Element => {
    const commonProps = {
      'data-xds': 'Hyperlink',
      className,
      ref: forwardedRef,
      ...props,
    };

    const ExternalIcon = (
      <Styled.IconContainer>
        <Styled.ExternalIcon aria-hidden="true" width={18} height={18} />
      </Styled.IconContainer>
    );

    if (to) {
      return (
        <Styled.RouterLink to={to} {...commonProps} {...props}>
          {children}
          {external && ExternalIcon}
        </Styled.RouterLink>
      );
    }

    return (
      <Styled.Link href={href} {...commonProps} {...props}>
        {children}
        {external && ExternalIcon}
      </Styled.Link>
    );
  }
);

Hyperlink.displayName = 'Hyperlink';
