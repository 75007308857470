import type { FC } from 'react';

import type { RelatedProfile } from '@xing-com/crate-common-graphql-types';
import { Container } from '@xing-com/layout-primitives';

import { PageSection } from '../components/page-section';
import { RelatedProfileCard } from '../components/related-profile-card';
import { RelatedProfileCarousel } from '../components/related-profile-carousel';

export const SimilarProfiles: FC<{ data?: (RelatedProfile | null)[] }> = ({
  data,
}) => {
  if (!data?.length) return null;

  const filteredData = data.filter((profile) => profile !== null);
  if (filteredData.length === 0) return null;

  return (
    <PageSection
      headlineTranslationKey="PROFILE_LO_FE_SIMILAR_PROFILES_HEADLINE"
      dataQa="similar-profiles-section"
    >
      <Container>
        <RelatedProfileCarousel>
          {data.map((user, index) =>
            user ? (
              <RelatedProfileCard
                key={index}
                otherProfile={user}
                trackingParamWithoutPrefix="upsell_similar_profiles_profiles"
              />
            ) : null
          )}
        </RelatedProfileCarousel>
      </Container>
    </PageSection>
  );
};
