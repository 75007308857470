import type { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Tag as XingTag } from '@xing-com/tag';
import {
  xdlColorControlFillQuaternary,
  xdlColorTextInvert,
  spaceM,
} from '@xing-com/tokens';

export interface IProps {
  children: ReactNode;
  highlighted?: boolean;
}

const StyledXingTag = styled(XingTag)<{ highlighted?: boolean }>`
  ${({ highlighted }) =>
    highlighted
      ? css`
          & {
            background-color: #616d7d !important;

            @media (prefers-color-scheme: light) {
              background-color: ${xdlColorControlFillQuaternary} !important;
              border-color: ${xdlColorControlFillQuaternary} !important;
              color: ${xdlColorTextInvert} !important;
            }
          }
        `
      : null}
  display: inline-flex;
  margin-bottom: ${spaceM};
  margin-right: ${spaceM};
  user-select: auto;
  pointer-events: none;
`;

export const Tag: FC<IProps> = ({ children, highlighted }) => (
  <StyledXingTag highlighted={highlighted}>{children}</StyledXingTag>
);
