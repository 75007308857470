import styled from 'styled-components';

import {
  scale080,
  spaceL,
  spaceXS,
  spaceXXL,
  spaceXXS,
  xdlColorBorder,
  xdlColorBorderStrong,
  xdlColorText,
} from '@xing-com/tokens';

export const OccupationContainer = styled.div`
  padding-bottom: ${spaceL};
  padding-left: calc(${spaceXXL} + ${spaceXXS});
  padding-top: ${spaceXS};
  position: relative;
  word-wrap: break-word;
`;

export const OccupationText = styled.div`
  font-size: ${scale080};

  p {
    font-size: inherit;
    margin: 0;
  }

  a {
    color: ${xdlColorText};
    font-weight: bold;
    border-bottom: 1px solid ${xdlColorBorder};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      border-bottom-color: ${xdlColorBorderStrong};
    }
  }
`;
