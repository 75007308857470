import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceS,
  xdlColorText,
  xdlPaletteGrayCool07,
  xdlPaletteGrayPlain04,
  xdlPaletteGrayPlain11,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledLi = styled.li`
  list-style: none;

  @media ${mediaConfined} {
    display: table-row;
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: ${spaceL};
  }

  .title.title {
    margin-bottom: ${spaceS};

    @media ${mediaConfined} {
      margin-bottom: 0;
      padding-right: ${spaceL};
      display: table-cell;
      vertical-align: middle;
    }
  }
`;

export const Level = styled.div`
  background-color: ${xdlPaletteGrayPlain11};
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 35px;
  padding: 0 ${spaceL};

  @media (prefers-color-scheme: light) {
    background-color: ${xdlPaletteGrayPlain04};
  }

  @media ${mediaConfined} {
    vertical-align: middle;
    display: table-cell;
    width: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    width: 706px;
  }
`;

export const LevelIndicator = styled.div`
  background-color: ${xdlPaletteGrayCool07};
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 35px;

  @media (prefers-color-scheme: light) {
    background-color: ${xdlPaletteGrayPlain11};
  }
`;

export const StyledBodyCopy = styled(BodyCopy)`
  position: relative;
  color: white;
  margin: 0 !important;

  &.levelTitleEmpty {
    color: ${xdlColorText};
  }
`;
