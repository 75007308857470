import type { FC } from 'react';
import styled from 'styled-components';

import type {
  UserFlagsDisplayFlag,
  HeaderImage,
} from '@xing-com/crate-common-graphql-types';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { cornerRadiusL, xdlColorSkeletonPrimary } from '@xing-com/tokens';
import { Row } from '@xing-com/xing-grid';

import { CustomImage } from './custom-image';
import { DefaultImage } from './default-image';

const StyledRow = styled(Row)`
  overflow: hidden;
  position: relative;
  height: calc(
    100vw / 2.05
  ); /* 2.05 is a background picture aspect ratio 984/480 */
  max-height: 30vh;
  min-height: 100px;
  background-color: ${xdlColorSkeletonPrimary};
  padding: 0;

  @media ${mediaConfined} {
    max-height: 220px;
    border-top-left-radius: ${cornerRadiusL};
    border-top-right-radius: ${cornerRadiusL};
  }

  @media ${mediaWide} {
    max-height: 260px;
  }
`;

export interface IProps {
  hasDefaultHeaderImage: boolean;
  displayFlag: UserFlagsDisplayFlag | null;
  headerImage?: Array<HeaderImage> | null;
}

export const CoverImage: FC<IProps> = ({
  hasDefaultHeaderImage,
  displayFlag,
  headerImage,
}) => {
  return (
    <StyledRow data-qa="xing-id-cover-image">
      {hasDefaultHeaderImage ? (
        <DefaultImage displayFlag={displayFlag} />
      ) : (
        <CustomImage headerImage={headerImage} />
      )}
    </StyledRow>
  );
};
