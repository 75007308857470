import styled from 'styled-components';

import { Button } from '@xing-com/button';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWideNavless,
  standardTypeMaxWidth,
} from '@xing-com/layout-tokens';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { space3XL, space4XL, spaceL, spaceXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledChildren = styled.div`
  width: 100%;
`;

export const StyledContainer = styled.div`
  max-width: ${standardTypeMaxWidth};
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
`;

export const StyledContent = styled.div`
  width: 100%;
  flex: 1 1 50%;
  display: flex;

  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceL};
    align-items: center;
  }

  @media ${mediaWideNavless} {
    align-items: center;
  }
`;

export const StyledImage = styled(SuperEllipse)`
  width: ${space3XL};
  height: ${space3XL};
  margin-right: ${spaceL};

  @media ${mediaWideNavless} {
    width: ${space4XL};
    height: ${space4XL};
    margin-right: ${spaceXL};
  }
`;

export const StyledSubHeadline = styled(BodyCopy)`
  margin-bottom: 16px;
  @media ${mediaSmallOrTiny} {
    display: none;
  }

  @media ${mediaWideNavless} {
    margin-bottom: 0;
  }
`;

export const StyledTextContainer = styled.div`
  flex: 1;

  @media ${mediaConfined} {
    padding-right: 24px;
  }
`;

export const StyledCtaButton = styled(Button)`
  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;
