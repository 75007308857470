import type { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  Container,
  IconContainer,
  Link,
  StyledIconArrowRight,
} from './show-more.styles';

interface IProps {
  size?: 'large';
  bold?: boolean;
  translationKey: string;
}

export const ShowMore: FC<IProps> = ({ size, bold, translationKey }) => {
  const iconSize = size === 'large' ? 24 : 18;
  const intlInstance = useIntl();
  const text = intlInstance.formatMessage({
    id: translationKey,
  });
  return (
    <Container $isLarge={!!size} $bold={!!bold}>
      <Link title={text}>
        {text}
        <IconContainer>
          &nbsp;
          <StyledIconArrowRight width={iconSize} height={iconSize} />
        </IconContainer>
      </Link>
    </Container>
  );
};
