import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IconExternalLink } from '@xing-com/icons';
import {
  scale100,
  spaceXXS,
  xdlColorLink,
  xdlColorLinkHover,
  xdlColorLinkVisited,
} from '@xing-com/tokens';

// *** Base ***
const baseHyperlink = css`
  color: ${xdlColorLink};
  font-size: inherit;

  &:hover,
  &:focus {
    color: ${xdlColorLinkHover};
  }

  &:visited {
    color: ${xdlColorLinkVisited};
  }
`;

// *** Components ***
export const Link = styled.a`
  ${baseHyperlink};
`;

export const RouterLink = styled(ReactRouterLink)`
  ${baseHyperlink};
`;

export const ExternalIcon = styled(IconExternalLink)`
  bottom: -3px;
  margin-left: ${spaceXXS};
  position: absolute;
`;

export const IconContainer = styled.span`
  display: inline-block;
  position: relative;
  width: ${scale100};
`;
