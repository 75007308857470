import { useEffect, useState } from 'react';

export const executeAfterWindowLoad = (fn: () => void): void => {
  // then let's check if dom already loaded
  if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.readyState === 'loaded' ||
    document.readyState === 'complete'
  ) {
    fn();
  } else {
    // if dom not loaded, then let's add event listener and execute after
    window.addEventListener('load', fn);
  }
};

export const useLazyLoad = <T>(value: T): T | null => {
  const [lazyValue, changeLazyValue] = useState<T | null>(null);

  useEffect(() => {
    executeAfterWindowLoad(() => {
      changeLazyValue(value);
    });
  }, [value]);

  return lazyValue;
};
